import React from "react"
import { Helmet } from "react-helmet"
import { graphql } from "gatsby"
import * as BS from "react-bootstrap"
import "bootstrap/dist/css/bootstrap-grid.css"


const ThankYou = props => {


  return (
    <section>
      <Helmet>
        <html lang="en" />
        <title>Congrats! You're A Unicorn Alert Member</title>
        <meta
          name="title"
          content="Congrats! You're A Unicorn Alert Member"
        ></meta>
        <meta
          name="description"
          content="Get the latest updates from startups, investors and fundraising in your realtime Unicorn Alert dashboard."
        />
      </Helmet>
    
      <BS.Container style={{ paddingLeft: 0, paddingRight: 0, maxWidth: 1400}}>

      <BS.Row style={{display: 'block', textAlign: 'center', marginTop: 70, marginBottom: 150}}>
      <p>Step 2 / 2</p>
      <h2 style={{color: '#34383b', fontWeight: 300, marginTop: 0}}>Welcome to Unicorn Alert <span role="img" aria-label="fire">🎉</span></h2>
<h1 style={{color: 'rgb(4 23 44)', fontSize: 60, fontWeight: 700, marginTop: 0, fontFamily: '"CircularStd", sans-serif', paddingLeft: 10, paddingRight: 10}}>What happens now?</h1>

<h4 style={{color: '#34383b', fontWeight: 300, marginTop: 25, marginBottom: 25, paddingLeft: 10, paddingRight: 10}}>1 - Confirm your email <span role="img" aria-label="fire">✉️</span></h4>
<p style={{maxWidth: 700, textAlign: 'center', margin: 'auto'}}>You will recieve a first email from <a href="mailto:john@unicornalert.io" style={{color: '#007bff', textDecoration: 'underline'}}>john@unicornalert.io</a> with your confirmation link (you may need to check your spam box).</p>
<h4 style={{color: '#34383b', fontWeight: 300, marginTop: 25, marginBottom: 25, paddingLeft: 10, paddingRight: 10}}>2 - Get your 1st set of data <span role="img" aria-label="data">📈</span></h4>
<p style={{maxWidth: 700, textAlign: 'center', margin: 'auto'}}>A second email will arrive with the data from the previous week.</p>
<h4 style={{color: '#34383b', fontWeight: 300, marginTop: 25, marginBottom: 25, paddingLeft: 10, paddingRight: 10}}>3 - See you on Monday <span role="img" aria-label="alert">⏰!</span></h4>
<p style={{maxWidth: 700, textAlign: 'center', margin: 'auto'}}>Every Monday morning, the latest investment dataset will be in your inbox.</p>
    
      
      </BS.Row>
      

     

        
      </BS.Container>
    
</section>
   
  )
}

export const query = graphql`
  {
    allFunding(sort: { order: DESC, fields: date }, limit: 28) {
      edges {
        node {
          amount
          date
          investors
          currency
          id
          businessRef {
            ... on Business {
              id
              name
              website
              what
              icon
              locations {
                city
                country
              }
            }
          }
        }
      }
      totalCount
    }
  }
`
export default ThankYou
